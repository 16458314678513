export const structure = {
  map: {},
  city: null,
  fields: {
    roads: null
  },
  layersConfig: null,
  objectCard: null,
  objectEditor: null,
  activeLayers: [],
  layerObjectList: null,
  hasObjectChanges: false
}

export const nonClearableFields = ['map']
