export const filterObjectByKeys = (obj, fields, exclude = false) => {
  return Object.entries(obj).reduce((acc, [k, v]) => {
    if (!exclude ? !fields.includes(k) : fields.includes(k)) {
      acc[k] = v
    }

    return acc
  }, {})
}

export const cloneDeep = (obj, cache = new WeakMap()) => {
  if (typeof obj !== 'object' || obj === null) return obj

  if (cache.has(obj)) return cache.get(obj)

  const clone = Array.isArray(obj) ? [] : {}
  cache.set(obj, clone)

  for (let key in obj) {
    clone[key] = cloneDeep(obj[key], cache)
  }

  return clone
}

export const randomInRange = (min, max) =>
  Math.floor(Math.random() * (max - min + 1)) + min

// TODO: isObjectsEqual

export const isArraysEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
    return false
  }

  if (arr1.length !== arr2.length) {
    return false
  }

  function isEqual(a, b) {
    if (
      typeof a !== 'object' ||
      typeof b !== 'object' ||
      a === null ||
      b === null
    ) {
      return a === b
    }

    if (Array.isArray(a) && Array.isArray(b)) {
      if (a.length !== b.length) {
        return false
      }

      for (let i = 0; i < a.length; i++) {
        if (!isEqual(a[i], b[i])) {
          return false
        }
      }

      return true
    }

    const keysA = Object.keys(a)
    const keysB = Object.keys(b)

    if (keysA.length !== keysB.length) {
      return false
    }

    for (const key of keysA) {
      if (!isEqual(a[key], b[key])) {
        return false
      }
    }

    return true
  }

  return isEqual(arr1, arr2)
}
