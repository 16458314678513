const systemConfig = JSON.parse(localStorage.getItem('systemConfig'))

export default {
  locale: systemConfig?.locale || 'ru',
  theme: null,
  isFullscreen: false,
  mapLoadingIndicaor: false,
  activeRequests: [],
  downloads: {},
  profileVersion: 11, // Is user have to recreate profile profileVersion++ // Int
  abbr: !!systemConfig?.abbr,
  env: {},
  availableCities: [],
  availableRoutes: new Set(),
  notification: null,
  storeRelations: {
    pdd: 'PDD_SET',
    gis: 'GIS_SET',
    info: 'INFO_SET',
    mciud: 'INFO_SET',
    ksodd: 'KSODD_SET',
    asudd: 'ASUDD_SET',
    tsodd: 'TSODD_SET',
    parking: 'PARKING_SET',
    licenses: 'LICENSES_SET',
    modeling: 'MODELING_SET',
    scenario: 'SCENARIO_SET',
    emergency: 'EMERGENCY_SET',
    roadWorks: 'ROAD_WORKS_SET',
    road_works: 'ROAD_WORKS_SET',
    parking_space: 'PARKING_SET',
    itsControl: 'ITS_CONTROL_SET',
    traffic_violations: 'PDD_SET',
    digitalTwin: 'DIGITAL_TWIN_SET',
    digital_twin: 'DIGITAL_TWIN_SET',
    dispatch_control: 'EMERGENCY_SET',
    isochronesAnalysis: 'ISOCHRONES_SET',
    efficiency_control: 'ITS_CONTROL_SET',
    tsoddSecondary: 'TSODD_SECONDARY_SET',
    road_consulting: 'TSODD_SECONDARY_SET',
    publicTransport: 'PUBLIC_TRANSPORT_SET',
    public_transport: 'PUBLIC_TRANSPORT_SET',
    transportSituation: 'TRANSPORT_SITUATION_SET'
  },
  storeCleaners: {
    pdd: 'PDD_STORE_RESET',
    gis: 'GIS_STORE_RESET',
    info: 'INFO_STORE_RESET',
    mrir: 'MRIR_STORE_RESET',
    ksodd: 'KSODD_STORE_RESET',
    asudd: 'ASUDD_STORE_RESET',
    tsodd: 'TSODD_STORE_RESET',
    parking: 'PARKING_STORE_RESET',
    licenses: 'LICENSES_STORE_RESET',
    modeling: 'MODELING_STORE_RESET',
    scenario: 'SCENARIO_STORE_RESET',
    emergency: 'EMERGENCY_STORE_RESET',
    road_works: 'ROAD_WORKS_STORE_RESET',
    'its-control': 'ITS_CONTROL_STORE_RESET',
    'digital-twin': 'DIGITAL_TWIN_STORE_RESET',
    tsoddSecondary: 'TSODD_SECONDARY_STORE_RESET',
    'isochrone-analysis': 'ISOCHRONES_STORE_RESET',
    'monitoring-pt': 'PUBLIC_TRANSPORT_STORE_RESET',
    'transport-situation': 'TRANSPORT_SITUATION_STORE_RESET'
  },
  assignMutations: {
    gis: 'GIS_ASSIGN',
    ksodd: 'KSODD_ASSIGN',
    digitalTwin: 'DIGITAL_TWIN_ASSIGN',
    modeling: 'MODELING_ASSIGN'
  },
  additionalGisLayers: {},
  isDrawing: false
}
