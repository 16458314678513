export const structure = {
  city: null,
  map: {},
  layers: [],
  layersOnMap: {},
  filters: {},
  aliases: {},
  dashboardFilters: {},
  dashboardWidgets: [],
  isSelectDisabled: false,
  visiblePanels: {
    left: true,
    bottom: true
  }
}

export const nonClearableFields = [
  'layersOnMap',
  'visiblePanels',
  // 'city',
  'map',
  'filters',
  'dashboardFilters',
  'dashboardWidgets'
]
